import React, { useState, useEffect, useRef, ClipboardEvent } from 'react'
import io, { Socket } from 'socket.io-client';
import { useForm, SubmitHandler } from 'react-hook-form'
import './Chat.css'
import EmojiPicker, { EmojiClickData } from 'emoji-picker-react';

interface MessageFormValues {
  messageText: string,
  messageImage: string,
}

interface AckData {
  readByUsername: string,
  timestamp: string,
}

interface Message {
  from: string,
  avatarUrl: string,
  messageText: string,
  messageImage: string,
  message_timestamp: string,
  messageReadBy: Map<string, string>,
}

interface User {
  username: string,
  avatarUrl: string,
}

enum WebsocketAuthError {
  FORCE_AUTHENTICATION = 'force_authentication',
  ID_TOKEN_REFRESHED = 'id_token_refreshed'
}

const PAGE_TITLE = 'Chat'
const WS_URL = process.env.REACT_APP_WEBSOCKET_URL ?? ''

const sendNotification = (message: string, unreadMessagesCount: number) => {
  // Desktop notification
  if ('Notification' in window) {
    if (Notification.permission === 'granted') { 
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const notification = new Notification(message)
    } else if (Notification.permission !== 'denied') {
      Notification.requestPermission().then((permission) => {
        if (permission === 'granted') {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const notification = new Notification(message)
        }
      });
    }
  }

  // Change browser tab title
  document.title = `(${unreadMessagesCount}) new messages`

  // Play notification sound
  const audio = new Audio(`${process.env.PUBLIC_URL}/notification.mp3`);
  audio.play();
}

const getDateTimeString = (timestamp: string): string => {
  const date = new Date(timestamp);
  const hour = date.getHours().toString();
  let minute = date.getMinutes().toString();
  minute = minute.length === 2 ? minute : `0${minute}`;

  return `${hour}:${minute}`;
}

function Chat({ isLoggedIn, username, websocketAuthErrorHandler }: {
   isLoggedIn: boolean,
   username: string,
   websocketAuthErrorHandler: () => void
}) {
  const [socket, setSocket] = useState<Socket | null>(null)
  const [messages, setMessages] = useState<Array<Message>>([])
  const [messagesLoaded, setMessagesLoaded] = useState(false)
  const [users, setUsers] = useState<Array<User>>([])
  const [usersTyping, setUsersTyping] = useState<Array<string>>([])
  const isUserTyping = useRef(false)
  const typingEventTimeoutRef = useRef<ReturnType<typeof setTimeout> | undefined>(undefined)
  const unreadMessagesCount = useRef(0)
  const sendMessageInputRef = useRef<HTMLInputElement|null>(null)
  const imagePreview = useRef<HTMLImageElement|null>(null)
  const imageData = useRef<HTMLInputElement|null>(null)
  const [isEmojiPickerDisplayed, setEmojiPickerDisplayed] = useState(false)

  // Check for page visibility - used for notifications
  const usePageVisibility = () => {
    const isVisibleRef = useRef(!document.hidden)
    const onVisibilityChange = () => {
      unreadMessagesCount.current = 0
      document.title = PAGE_TITLE
      isVisibleRef.current = !document.hidden
      if (!document.hidden) {
        socket?.emit('ack', username)
      }
    }
  
    React.useEffect(() => {
      document.addEventListener('visibilitychange', onVisibilityChange, false)
  
      return () => {
        document.removeEventListener('visibilitychange', onVisibilityChange)
      }
    })
  
    return isVisibleRef
  }
  const isPageVisible = usePageVisibility()
  
  // Scroll to bottom after each render
  useEffect(() => {
    const scrollToBottom = () => {
      const sendMessageButton = sendMessageInputRef.current
      if (sendMessageButton) {
        sendMessageButton.scrollIntoView()
      }
    }
    scrollToBottom()
  })

  // Initialize websocket
  useEffect(() => {
    const createSocket = () => {
      const socket = io(WS_URL, {
        transports: ['websocket', 'polling'],
        withCredentials: true,
        secure: true
      })
    
      socket.on('receive_message', (data) => {
        const message = JSON.parse(data)
        setMessages(messages => messages.concat(message))
        if (message.from !== username && !isPageVisible.current) {
          unreadMessagesCount.current++
          sendNotification(`Message from ${message.from}`, unreadMessagesCount.current)
        } else if (message.from !== username && isPageVisible.current) {
          socket?.emit('ack', username)
        }
      })
    
      socket.on('update_users_list', (data) => {
        setUsers(JSON.parse(data))
      })
    
      socket.on('existing_messages', (data) => {
        setMessages(JSON.parse(data))
        setMessagesLoaded(true)
      })
    
      socket.on('user_started_typing', (usernameTyping) => {
        setUsersTyping(usernames => {
          if (usernames.indexOf(usernameTyping) === -1) {
            return usernames.concat(usernameTyping)
          }
          return usernames
        })
      })
    
      socket.on('user_stopped_typing', (usernameTyping) => {
        setUsersTyping(usernames => {
          if (usernames.indexOf(usernameTyping) !== -1) {
            return usernames.filter(username => username !== usernameTyping)
          }
          return usernames
        })
      })

      socket.on('ack', (ackData) => {
        handleUserAck(JSON.parse(ackData))
      })
    
      socket.on(WebsocketAuthError.FORCE_AUTHENTICATION, () => {
        websocketAuthErrorHandler()
      })
    
      socket.on(WebsocketAuthError.ID_TOKEN_REFRESHED, (data) => {
        socket.disconnect()
        document.cookie = `id_token=${JSON.parse(data).idToken}; Domain=${process.env.REACT_APP_DOMAIN_NAME}; SameSite=Lax; Secure; HttpOnly`
        createSocket()
      })
    
      setSocket(socket)
    }

    if (isLoggedIn && !socket) {
      createSocket()
    }
        
  }, [websocketAuthErrorHandler, isLoggedIn, isPageVisible, socket, username])
    
  // Initialize chat form
  const { register, handleSubmit, reset, setValue, getValues} = useForm<MessageFormValues>()
  
  // Message form submit handler
  const handleMessageSubmitted: SubmitHandler<MessageFormValues> = data => {
    const message = JSON.stringify(data)
    socket?.emit('send_message', message)
    if (imagePreview.current) {
      imagePreview.current.src = ''; 
    }
    setEmojiPickerDisplayed(false);
    reset()
  }

  // Typing event handler - debounce and emit user_started_typing and user_stopped_typing messages
  const handleTypingEvent = () => {
    if (isUserTyping.current === true) {
      clearTimeout(typingEventTimeoutRef.current)
      typingEventTimeoutRef.current = setTimeout(() => {
        isUserTyping.current = false
        socket?.emit('user_stopped_typing', username)
      }, 2000)
      return
    }
    isUserTyping.current = true
    socket?.emit('user_started_typing', username)
    typingEventTimeoutRef.current = setTimeout(() => {
      isUserTyping.current = false
      socket?.emit('user_stopped_typing', username)
    }, 2000)
  } 

  // Paste event handler - if image was pasted, save it to messageImage hidden input and display preview
  const handlePaste = (event: ClipboardEvent<HTMLInputElement>) => {
    const clipboardItems = event.clipboardData.items;
    const images: Blob[] = [];
    
    for (let i = 0; i < clipboardItems.length; i++) {
      const item = clipboardItems[i];
      if (item.kind === 'file' && item.type.indexOf('image') !== -1) {
        images.push(item.getAsFile() as Blob);
      }
    }

    if (images.length) {
      const reader = new FileReader();
      reader.onload = e => {
        if (imagePreview.current && imageData.current && e && e.target && e.target.result && typeof e.target.result === 'string') {
          imagePreview.current.src = e.target.result
          setValue('messageImage', e.target.result)
        }
      }
      reader.readAsDataURL(images[0])
    }

    return
  }

  // Emoji selected event handler - add selected emoji to message input
  const handleEmojiSelected = (emojiData: EmojiClickData, event: MouseEvent) => {
    const currentMessageText = getValues('messageText');
    setValue('messageText', `${currentMessageText} ${emojiData.emoji}`)
  }

  // Toggle emoji puicker button handler - Display/hide emoji picker
  const handleEmojiPickerToggle = () => {
    setEmojiPickerDisplayed(isDisplayed => !isDisplayed)
  }

  // Handle the event, when user sent the ack event
  const handleUserAck = (ackData: AckData) => {
    let latestReadMessageMarked = false
    setMessages(
      messages => messages.reverse().map(message => {
        // Find the latest message, other than my messages and mark is as read
        if (!latestReadMessageMarked && message.from !== ackData.readByUsername) {
          if (!message.messageReadBy) {
            message.messageReadBy = new Map();
          }
          message.messageReadBy.set(ackData.readByUsername, ackData.timestamp);
          latestReadMessageMarked = true
          // If we already marked the latest message as read, remove the mark flag from all older messages
        } else if (latestReadMessageMarked && Array.isArray(message.messageReadBy)) {
          message.messageReadBy = new Map([...message.messageReadBy].filter(([readByUsername, timestamp]) => readByUsername !== ackData.readByUsername));
        }

        return message;
      }).reverse()
    )
  }

  return ( 
    <>
      {users.length > 0 &&
        <>
          <div className="people-list" id="people-list">
            <ul>
              {users.map((user, idx) => (
                <li key={idx} className="clearfix">
                  <img className="inline-icon" src={user.avatarUrl} alt="profile_icon" />
                  <div className="about">
                    <div className="name">{user.username}</div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </>
      }
      
      {messages.length > 0 &&
        <div className="chat-messages">
          {messages.map((message, idx) => {
            const isOwnMessage = username === message.from
            const ownOtherClass = isOwnMessage ? 'own' : 'other'
            return (
              <div className="bubble-wrapper" key={idx}>
                <div className={`inline-container ${isOwnMessage ? 'own' : ''}`}>
                  <div className={`${ownOtherClass}-bubble ${ownOtherClass}`}>
                    <div className="msg-info">
                      <img className="inline-icon message-icon" src={message.avatarUrl} alt="profile_icon" />
                      <div className="msg-info-name">{message.from}</div>
                      <div className="msg-info-time">{getDateTimeString(message.message_timestamp)}</div>
                    </div>
                    {message.messageText}
                    <br /><br />
                    {message.messageImage && 
                      <img className="msg-image"src={message.messageImage} alt=""/>
                    }
                    <br></br>
                    {message.messageReadBy && message.messageReadBy.size > 0 &&
                      <div>  
                        Read by: {[...message.messageReadBy].map(([readByUsername, timestamp]) => {
                          return (
                            <span key={readByUsername}>{readByUsername} at {getDateTimeString(timestamp)}</span>
                          )
                        })}
                      </div>
                    }
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      }

      {isLoggedIn && socket && socket.connected && messagesLoaded &&
        <>
          <div><i>{usersTyping.length > 0 ? usersTyping.join(', ') + ' typing...': ''}</i></div>
          <form className="msger-inputarea" onSubmit={handleSubmit(handleMessageSubmitted)}>
            <input {...register('messageText')} onKeyUp={handleTypingEvent} onPaste={handlePaste} className="msger-input" placeholder="Enter your message..." autoFocus />
            <input {...register('messageImage')} type="hidden" ref={imageData} />
            <input type="submit" value="Send Message" className="msger-send-btn" ref={sendMessageInputRef}/>
            <input type="button" value="🙂" className="msger-emoji-btn" onClick={handleEmojiPickerToggle}/>
          </form>
          { isEmojiPickerDisplayed &&
            <EmojiPicker width="100%" onEmojiClick={handleEmojiSelected}/>
          }
          <img ref={imagePreview} alt="" />
        </>
      }

        {isLoggedIn && socket && !socket.connected &&
          <p>Connecting...</p>
        }
    </>
  )
}

export default Chat
