import React, { useEffect, useState } from 'react'
import Chat from './Chat'
import './App.css'

interface LoginOKResponse {
  id_token: string
  username: string
}
interface LoginErrorResponse {
  error: string
  message: string
  statusCode: number
}
export type LoginResponse = LoginOKResponse | LoginErrorResponse
export type LoginHandler = (loginResponse: LoginResponse) => void

interface ChangePasswordOKResponse {
  result: string
}
interface ChangePasswordErrorResponse {
  error: string
  message: string
  statusCode: number
}
export type ChangePasswordResponse = ChangePasswordOKResponse | ChangePasswordErrorResponse
export type ChangePasswordHandler = (changePasswordResponse: ChangePasswordResponse) => void

enum CheckAuthError {
  TOKEN_INVALID = 'Provided ID token is invalid.',
  TOKEN_NOT_PRESENT = 'ID token not present in cookie.'
}

const AUTH_URL = `${process.env.REACT_APP_API_URL}/auth`
const COGNITO_AUTH_URL = process.env.REACT_APP_COGNITO_AUTH_URL ?? ''
const COGNITO_LOGOUT_URL = process.env.REACT_APP_COGNITO_LOGOUT_URL ?? ''

function App() {

  const [isLoggedIn, setLoggedIn] = useState(false);
  const [username, setUsername] = useState('');
  const [authError, setAuthError] = useState('');

  const login = () => {
    try {
      fetch(AUTH_URL, {credentials: 'include'})
      .then(loginResponse => loginResponse.json())
      .then(loginResponse => {
        if ('error' in loginResponse) {
          if (loginResponse.error === CheckAuthError.TOKEN_INVALID) {
            setAuthError('ID token invalid.')
          }
          if (loginResponse.error === CheckAuthError.TOKEN_NOT_PRESENT) {
            window.location.replace(COGNITO_AUTH_URL)
          }
        }
        if ('username' in loginResponse) {
          setAuthError('')
          setLoggedIn(true)
          setUsername(loginResponse.username)
        }
      })
    } catch (e) {
      setAuthError('An error occured while checking, if user is logged in.')
    }
  }

  useEffect(() => {
    login()
  }, [])

  return (
    <>
      <h2 className='mainHeader'> Sloni Chat :) </h2>

      { authError &&
        <p>
          {authError}<br />
          Please <a href={COGNITO_AUTH_URL}> login </a>
        </p>
      }

      { isLoggedIn &&
      <div className='userInfo'>
        Logged in as <b>{username}</b> (<a href={COGNITO_LOGOUT_URL}>logout</a>)
      </div>
      }

      <Chat 
        isLoggedIn={isLoggedIn}
        username={username}
        websocketAuthErrorHandler={login}
      />

    </>
  )
}

export default App
